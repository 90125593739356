import React, { useEffect, useState } from 'react'
import { MY_EVENTS_URL, generalGetMethos, uploadInvoice } from '../requests';

const KesilecekFatura = () => {


    const [uploadedImage, setUploadedImage] = useState(null)
    const [displayImage, setDisplayImage] = useState(null)
    const [ErrMsg, setErrMsg] = useState();
    const [allevents, setallevents] = useState([]);
    const [selectedEventId, setselectedEventId] = useState('')

    const handleSubmit  = (event) => {
        event.preventDefault(); 
          //create a formData as payload and append all form values
          const formData = new FormData();
        //   const parsedPayload = JSON.parse(payloadItems)
        //   for (const key in parsedPayload){
        //     formData.append(key,parsedPayload[key])
        //     // console.log(payloadItems.key)
        //   }
      
          //append file
          formData.append('file',uploadedImage)
          formData.append('event_id',selectedEventId)
          uploadInvoice(formData).then((response) => { 
            if (response.status===200) {
              alert("Fatura başarıyla yüklendi!")
            }else{
              alert("Hata! Fatura dosyası yüklenemedi")
            }
           }).catch()
           
          }   


    useEffect(() => {

      generalGetMethos(MY_EVENTS_URL).then(
        (response) => { 
          if (!response.status) {            
            //handle error
          } else if(response.status===200){
            if (response.data.events.length>0) {
              setallevents(response.data.events)
            }
            // console.log('my events',response.data);
          }
        }
        
      ).catch()
   
  }, [])
        


  return (
    <div className='flex h-screen w-full items-center justify-center'>
        <div className='w-fit  flex-col justify-end h-[60%]'>
        <form action="" onSubmit={handleSubmit}
         className='bg-white rounded-md h-full pt-4 px-[2rem] w-full'>
          <div className='w-full space-y-3'>
            <div className='w-full'>
              <select
              value={selectedEventId}
              onChange={(event) => {setselectedEventId(event.target.value)}}
               className='w-full' name="" id="">
                <option value="">Etkinliği Seç</option>
                {allevents&& allevents.map((event) => (
                  <option key={event.id} value={event.id}>
                    {"#"+event.id+' '+event.etkinlik_adi}
                  </option>
                ))}
              </select>
            </div>
            <label htmlFor="fatura-file"
            className='bg-gray-200 px-2 rounded-md mt-5 cursor-pointer'
            >
                Fatura Dosya Seç
            </label>
            <div className='flex justify-center'>
                <input type="file" name='profile_pic'  
                    id='fatura-file'
                    className='rounded-md '
                    placeholder='hhhhh'
                    // value={uploadedImage}
                    onChange={(e) => {setUploadedImage(e.target.files[0]);                        
                        setDisplayImage(URL.createObjectURL(e.target.files[0]))}}
                />

                {/* upload/save button */}
                <button type='submit'
                 className='rounded-sm bg-green-300 px-1 mr-1'>
                    Kaydet
                </button>
            </div>
          </div>
                
        </form>
            <div className='mt-3 w-full flex justify-center'>
                <img style={{maxHeight:'265px',maxWidth:'20rem'}} src={displayImage}
                className='w-full' alt=''>
                </img>
            </div>

        </div>
    </div>
  )
}

export default KesilecekFatura