import { useEffect, useState } from "react";
import "../../App.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CountrySelector from "../country-selector";
import { INSERT_BILLING_ADDRESS_URL, generalPostMethod, getCurrentUser, postPrepaymentData } from "../requests";
import CitiesJson from "../cities";
import ErrorComp from "../error-component";



const cities = Object.keys(CitiesJson);

const AddBillingAddress = (props) => {

  //address types
  const ind_add_type = 'individual';
  const org_add_type = 'organizational';
//   const location = useLocation();
//   const paymentInfo = location.state;

  //city selection
  const [province, setProvince] = useState('');
  const [district, setDistrict] = useState('');
  const [relatedDistricts, setRelatedDistricts] = useState([])

  const [address, setFaturaAddress] = useState('');
  const [full_name, setFullName] = useState('');
  // const [last_name, setLast_name] = useState('');
  const [phone, setPhone] = useState('');

  const [odemeTutari, setodemeTutari] = useState();
  const [country, setCountryLabel] = useState('Turkey'); //country name
  const [address_type, setaddressType] = useState(ind_add_type); //or organization
  const [tc_id, settcNo] = useState('');
  const [is_turkish, setIsTurkish] = useState(true);
  const [tel, setTel] = useState();

  //organizational address stuff
  const [address_name, setaddressName] = useState(null);
  const [vergi_kimlik_no, setvkn] = useState('');
  const [vergi_dairesi, setvergiDairesi] = useState('');
  const [organisation_name, setfirmaAdi] = useState('');

  const [event_id, setEvent_id] = useState('');
  const [user_id, setUser_id] = useState('');
  // const [ticket_id, setTicket_id] = useState('');
  const [user_email, SetUser_email] = useState('');

  const [errMsg, setErrMsg] = useState([]);


  const navigateThere = useNavigate();
  


// const fetchEventInfo = ()=> {

//   //retrieve and fill up etkinlik info
//   // let etkinlik_name = JSON.parse(localStorage.getItem('etkinlikAdi'));
//   // let etkinlik_price = JSON.parse(localStorage.getItem('etkinlikUcreti'));

//   // let etkinlik_date = JSON.parse(localStorage.getItem('etkinlikTarihi'));
//   // let etkinlik_venue = JSON.parse(localStorage.getItem('etkinlikMekan'));



//   // let userName = JSON.parse(localStorage.getItem('full_name'));
//   // let userLastName = JSON.parse(localStorage.getItem('last_name'));
//   // let userPhone = JSON.parse(localStorage.getItem('phone'));

//   // let email_ = JSON.parse(localStorage.getItem('email'));
//   // let event_code_ = JSON.parse(localStorage.getItem('etkinlikKodu'));
//   const event_info = paymentInfo.event_info[0];
//   setEtkinlikAdi(event_info.etkinlik_adi);
//   setodemeTutari(paymentInfo.payment_amount);
//   setEvent_id(event_info.id);
//   setEtkinlikTarihi(event_info.etkinlik_tarihi);
//   setEtkinlikMekan(event_info.etkinlik_mekan);
  
// }

//get current user
const getUserInfo = () => {
//   let user_id_ = JSON.parse(localStorage.getItem('user_id'));
  const results = getCurrentUser().then((response)=>{
    if (response.status===200) {
        const user_info = response.data.user;
        //update user info states
        // var parsedUser = JSON.parse(response?.data.user)
        // setFullName(user_info.full_name)
        // setLast_name(user_info.last_name)
        SetUser_email(user_info.email)
        setPhone(user_info.phone)   
        setUser_id(user_info.id) 
        
    }

    return response;
}).catch((errors)=>{return errors;});
}

//Create prepayment entry
const  createPrepaymentRecord=  ()=> {

  const results = postPrepaymentData(
    JSON.stringify({full_name,user_email,event_id,user_id,
    address,phone, odemeTutari})).then((response)=>{
      if (response.status===200) {
        const orderInfo = {"order_id":response.data.order_id, "payment_amount":odemeTutari};
        localStorage.setItem('order_id', JSON.stringify(response.data.order_id));
        navigateThere('/artirmali-odeme-yap',{"state":orderInfo});
      }
      }).catch((errors)=>{return errors;});

}

const handleSave = (event) =>{ 
  event.preventDefault();
  setErrMsg([]);
  generalPostMethod(INSERT_BILLING_ADDRESS_URL,
    JSON.stringify({
      organisation_name,
      user_id,
      full_name,
      country,
      province,
      district,
      vergi_kimlik_no,
      vergi_dairesi,
      address,
      address_name,
      address_type,
      is_turkish,
      tc_id,
      phone})
    ).then(
      (response) => { 
        if (!response.status) {
          //set error message, something went wrong
          if (response.response.data.errors) {
            setErrMsg(Object.values(response.response.data.errors));
            console.log('error',Object.values(response.error.response.data.errors));
            
          }
        } else if(response.status===200) {
          console.log('status',response.status);
          //set page to make payment
          props.props.readyForPayment();
        }
       }
    ).catch((error) => { 
     })

}

useEffect(()=>{ 
  //   let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));
  // if(loginVal=='true'){setIsLoggedin('true')};  
// setodemeTutari(props.props.payment_amount)
// setEvent_id(props.props.event_id)
//   //Get current user info
  getUserInfo();

  //get event info
//   fetchEventInfo();
   


  },[]);

  return (
    <div>
         {/* payment form div*/}
     <form onSubmit={handleSave}>
       <div>
         <div className="border-b-0 border flex-col"> 
         {errMsg&&
         <ErrorComp errors={errMsg}/>}
         {/* address type selector */}
             <div className="flex max-h-fit">
               <p
               onClick={() => { setaddressType(ind_add_type) }}
                className={(address_type===ind_add_type&&" bg-gray-600 ")+" h-fit m-0 w-[50%] border-[0.1rem] cursor-pointer"}
                >
                 Bireysel                        
               </p>

               <p  onClick={() => { setaddressType(org_add_type) }} 
               className={(address_type===org_add_type&&" bg-gray-600 ")+" h-fit m-0 w-[50%] border-[0.1rem] cursor-pointer"}
               >
                 Kurumsal

               </p>

          </div>
           
             {address_type===ind_add_type?
             // {/* individual address */}
           <div 
            className="bg-gray-600 h-full text-left pt-3 pl-1 text-sm">
             <p className=" w-[90%] flex justify-between">
               Ad Soyad: <input required
               
               value={full_name}
               onChange={(event) => { setFullName(event.target.value) }}
              className="h-[2.2rem]  rounded-md text-gray-600 w-[77%]"
              type="text" />
             </p>

             <div className="flex justify-end w-[90%] text-xs">
             <input type="checkbox" className="form-check-input mr-2"
              id="exampleCheck1" onChange={() => { setIsTurkish(!is_turkish) }}/>
               <p>T.C Vatandaşi değil</p>
             </div>

             {is_turkish&&
             <p className=" w-[90%] flex justify-between">
               T.C Kimlik No: 
             <input required                     
               value={tc_id}
               onChange={(event) => { settcNo(event.target.value) }}
              className="h-[2.2rem]  rounded-md text-gray-600 w-[77%]"
              type="text" />
             </p>
              }

             <p
              className=" w-[90%] flex justify-between">
               Tel:  
               <input required                     
               value={tel}
               onChange={(event) => { setTel(event.target.value) }}
               className="h-[2.2rem]  rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             <p
              className=" w-[90%] flex justify-between ">
               Ülke:  
               <div className="w-[77%]">
                 <CountrySelector setCountryLabel={setCountryLabel}/>
               </div>
             </p>
               {/* province and district selection for turkey only shows if country is turkey */}
               {country==='Turkey'&&
               <div className="w-full">
                   <p
                   className=" w-[90%] flex justify-between">
                     İL:  
                   <select name="" id=""
                   className="w-[77%] rounded-md text-gray-600"
                     value={province}
                     onChange={(event)=>{
                         setProvince(event.target.value);
                         setRelatedDistricts(CitiesJson[event.target.value]['districts'])}} >
                     <option value=''>İl</option>   
                       {cities.map(city => (
                       <option key={`city-${city}`} value={city}>
                           {city}
                     </option>))} 
                   </select>
                   </p>

                   {/* district/ district */}
                   <p
                   className=" w-[90%] flex justify-between">
                     İlçe:  
                     <select name="" id=""
                     className="w-[77%] rounded-md text-gray-600"
                     value={district}
                     onChange={(event)=>{setDistrict(event.target.value)}}
                     >
                   <option>İlçe</option>   
                   {relatedDistricts.map(district => (
                   <option key={`district-${district}`} value={district}>
                     {district}
                   </option>))}     
                     </select>
                   </p>
             </div>
            }
               {/* fatura address */}                    

             <div className="w-[90%] flex justify-between">
               <p>
                 Fatura Adresi
               </p>
               <textarea className="text-md rounded-md text-gray-600 w-[77%]"
               placeholder="Adres" required value={address} 
               onInput={(event)=>{setFaturaAddress(event.target.value)}}>

               </textarea>
             </div>

             <p
              className=" w-[90%] flex mb-0 mt-2 justify-between">
               Adres Başlığı:  
               <input required                     
               value={address_name}
               onChange={(event) => { setaddressName(event.target.value) }}
               className="h-[2.2rem]    rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             {/* <p>(province, ilçe, Mah., Sk., No.)</p> */}
           </div>//{/**end individual address */}
                //this column is compulsory for the iterany operator
               :


             // organizational adddress
           <div 
            className="bg-gray-600 h-full text-left pt-3 pl-1 text-sm">
             
             <p
              className=" w-[90%] flex justify-between">
               Firma Adı:  
               <input required                     
               value={organisation_name}
               onChange={(event) => { setfirmaAdi(event.target.value) }}
               className="h-[2.2rem]   rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             <p
              className=" w-[90%] flex justify-between ">
               Ülke:  
               <div className="w-[77%]">
                 <CountrySelector setCountryLabel={setCountryLabel}/>
               </div>
             </p>
               {/* province and district selection for turkey only shows if country is turkey */}
               {country==='Turkey'&&
               <div className="w-full">
                   <p
                   className=" w-[90%] flex justify-between">
                     İL:  
                   <select name="" id=""
                   className="w-[77%] rounded-md text-gray-600"
                     value={province}
                     onChange={(event)=>{
                         setProvince(event.target.value);
                         setRelatedDistricts(CitiesJson[event.target.value]['districts'])}} >
                     <option value=''>İl</option>   
                       {cities.map(city => (
                       <option key={`city-${city}`} value={city}>
                           {city}
                     </option>))} 
                   </select>
                   </p>

                   {/* district/ district */}
                   <p
                   className=" w-[90%] flex justify-between">
                     İlçe:  
                     <select name="" id=""
                     className="w-[77%] rounded-md text-gray-600"
                     value={district}
                     onChange={(event)=>{setDistrict(event.target.value)}}
                     >
                   <option>İlçe</option>   
                   {relatedDistricts.map(district => (
                   <option key={`district-${district}`} value={district}>
                     {district}
                   </option>))}     
                     </select>
                   </p>
               </div>
               }
               {/* fatura address */}
             <div className="w-[90%] flex justify-between">
               <p>
                 Fatura Adresi
               </p>
             <textarea className="text-md rounded-md text-gray-600 w-[77%]"
              placeholder="Adres" required value={address} 
              onInput={(event)=>{setFaturaAddress(event.target.value)}}>

              </textarea>
             </div>

              {/* vergi_kimlik_no and vergi dairesi */}
             <div className="flex justify-end w-[90%] py-1">
               <div className="flex w-[77%] space-x-2">
                 <div className="text-left w-full">
                   <p className="mb-0">VKN</p>
                   <input required                   
                     value={vergi_kimlik_no}
                     onChange={(event) => { setvkn(event.target.value) }}
                     className="h-[2.2rem]  rounded-md text-gray-600 w-full"
                     type="text" />
                 </div> {/**end vergi_kimlik_no */}

                 <div className="w-full">
                   <p className="mb-0">Vergi Dairesi</p>
                   <input required                     
                     value={vergi_dairesi}
                     onChange={(event) => { setvergiDairesi(event.target.value) }}
                     className="h-[2.2rem]   rounded-md text-gray-600 w-full"
                     type="text" />
                 </div> {/**end vergi dairesi */}
               </div>
             </div>

             <p
              className=" w-[90%] flex justify-between mt-2 mb-0">
               Adres Başlığı:  
               <input required                     
               value={address_name}
               onChange={(event) => { setaddressName(event.target.value) }}
               className="h-[2.2rem]   rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             {/* <p>(province, ilçe, Mah., Sk., No.)</p> */}
           </div>//{/**end organizational address */}
             }
         </div>
         
       </div>
             <div className="flex justify-around h-fit w-full">
               <p type=""
                onClick={() => { props.props.readyForPayment() }}
                  className=" bg-red-400 px-2 h-[1.6rem] cursor-pointer rounded-md mt-1"
                  >
                    Vazgeç
                </p>

                  <button type="submit"
                  className=" bg-green-400 px-2 h-[1.6rem] rounded-md mt-1"
                  >
                    Kaydet
                  </button>
             </div>
      </form>
    </div>
  )
}

export default AddBillingAddress