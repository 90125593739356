import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
// import '../App.css';
import axios from 'axios';
import { APPROVED_INVOICE_URL, DOWNLOAD_INVOICE, generalGetMethos } from '../requests';

// const ALL_EVENTS_URL = 'http://127.0.0.1:8000/api/all-billings';  //For dev
// const ALL_EVENTS_URL = 'https://api.buradago.com/public/api/all-billings';  //For p

const ApprovedInvoices = ({ data }) => {

  const [billings, setBillings] = useState([]);
  // const [trigerReloadBillings, settrigerReloadBillings] = useState(false)
// const [status, setstatus] = useState("");
const getApprovedInvoices =  ()=>{


    generalGetMethos(APPROVED_INVOICE_URL).then((response) => { 
      if (!response.status) {
        //handle errors
      }else if (response.status===200){
        if (response.data.approved_invoices.length>0) {
          setBillings(response.data.approved_invoices)
        }
      }
     }).catch()
}


useEffect(
  ()=>{getApprovedInvoices();},[]
);

  return (
    <div className='mt-[5%]'>
      <h2 className='text-white'>Yüklenen Faturalar</h2>
    <Table striped bordered hover className='table'>
      <thead>
        <tr>
          <th>Etkinlik Kodu</th>
          <th>Etkinlik Adı</th>
          <th>Fatura Durumu</th>
          <th>Etkinlik Tarihi</th>
          <th>İndir</th>
        </tr>
      </thead>
      <tbody>
        {billings.map((item, index) => (
          <tr key={index}>
            <td>{item.event_id}</td>
            <td>{item.etkinlik_adi}</td>
            <td>{item.status}</td>
            <td>{item.etkinlik_tarihi}</td>
            <td>{
              <a className='text-xs'
               href={DOWNLOAD_INVOICE+item.owner_invoice_url}
              target='_blank'>{item.owner_invoice_url}</a>
            
            }</td>
           
            
          </tr>
        ))}
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td> 
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td>
        </tr>
        
         
        
        
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}

      </tbody>
    </Table>
    </div>
  );
};

export default ApprovedInvoices;
