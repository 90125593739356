import React from 'react'
import { useEffect, useState } from "react";
import "../../App.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import CitiesJson from '../cities';
import { UPDATE_BILLING_ADDRESS_URL, generalPostMethod, getCurrentUser } from '../requests';
import CountrySelector from '../country-selector';

const cities = Object.keys(CitiesJson);

const EditBillnAddress = (props) => {

    //address types
  const ind_add_type = 'individual';
  const org_add_type = 'organizational';
//   const location = useLocation();
//   const paymentInfo = location.state;

  //city selection
  const [province, setProvince] = useState(props.props.address.province);
  const [district, setDistrict] = useState(props.props.address.district);
  const [relatedDistricts, setRelatedDistricts] = useState([])

  const [address, setFaturaAddress] = useState(props.props.address.address);
  const [full_name, setFullName] = useState(props.props.address.full_name);
  // const [last_name, setLast_name] = useState('');
  const [phone, setPhone] = useState(props.props.address.phone);

  const [country, setCountryLabel] = useState(props.props.address.country); //country name
  const [address_type, setaddressType] = useState(ind_add_type); //or organization
  const [tc_id, settcNo] = useState(props.props.address.tc_id);
  const [is_turkish, setIsTurkish] = useState(true);
  const [tel, setTel] = useState();

  //organizational address stuff
  const [address_name, setaddressName] = useState(props.props.address.address_name);
  const [vergi_kimlik_no, setvkn] = useState(props.props.address.vergi_kimlik_no);
  const [vergi_dairesi, setvergiDairesi] = useState(props.props.address.vergi_dairesi);
  const [organisation_name, setfirmaAdi] = useState(props.props.address.organisation_name);

  const [event_id, setEvent_id] = useState('');
  const [user_id, setUser_id] = useState('');
  // const [ticket_id, setTicket_id] = useState('');
  const [user_email, SetUser_email] = useState('');

//get current user
const getUserInfo = () => {
    //   let user_id_ = JSON.parse(localStorage.getItem('user_id'));
      const results = getCurrentUser().then((response)=>{
        if (response.status===200) {
            const user_info = response.data.user;
           
            SetUser_email(user_info.email)
            setPhone(user_info.phone)   
            setUser_id(user_info.id) 
            
        }
    
        return response;
    }).catch((errors)=>{return errors;});
    }

    const handleSave = (event) =>{ 
        event.preventDefault();
        generalPostMethod(UPDATE_BILLING_ADDRESS_URL,
          JSON.stringify({
            organisation_name,
            user_id,
            full_name,
            country,
            province,
            district,
            vergi_kimlik_no,
            vergi_dairesi,
            address,
            address_name,
            address_type,
            is_turkish,
            tc_id,
            phone,
            'id':props.props.address.id})
          ).then(
            (response) => { 
              if (!response.status) {
                //set error message, something went wrong
              } else if(response.status===200) {
                console.log('status',response.status);
                //set page to make payment
                props.props.readyForPayment();
              }
             }
          ).catch()
      
      }
  
        
    useEffect(()=>{ 
        setaddressType(props.props.address&&props.props.address.address_type)
     
      getUserInfo();
    
      //get event info
    //   fetchEventInfo();    
    
      },[]);


  return (
    <div>
         <form onSubmit={handleSave}>
       <div>
         <div className="border-b-0 border flex-col"> 
                    
             {address_type===ind_add_type?
             // {/* individual address */}
           <div 
            className="bg-gray-600 h-full text-left pt-3 pl-1 text-sm">
             <p className=" w-[90%] flex justify-between">
               Ad Soyad: <input required
               
               value={full_name}
               onChange={(event) => { setFullName(event.target.value) }}
              className="h-[2.2rem]  rounded-md text-gray-600 w-[77%]"
              type="text" />
             </p>

             <div className="flex justify-end w-[90%] text-xs">
             <input type="checkbox" className="form-check-input mr-2"
              id="exampleCheck1" onChange={() => { setIsTurkish(!is_turkish) }}/>
               <p>T.C Vatandaşi değil</p>
             </div>

             {is_turkish&&
             <p className=" w-[90%] flex justify-between">
               T.C Kimlik No: 
             <input required                     
               value={tc_id}
               onChange={(event) => { settcNo(event.target.value) }}
              className="h-[2.2rem]  rounded-md text-gray-600 w-[77%]"
              type="text" />
             </p>
              }

             <p
              className=" w-[90%] flex justify-between">
               Tel:  
               <input required                     
               value={phone}
               onChange={(event) => { setPhone(event.target.value) }}
               className="h-[2.2rem]  rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             <p
              className=" w-[90%] flex justify-between ">
               Ülke:  
               <div className="w-[77%]">
                 <CountrySelector setCountryLabel={setCountryLabel}/>
               </div>
             </p>
               {/* province and district selection for turkey only shows if country is turkey */}
               {country==='Turkey'&&
               <div className="w-full">
                   <p
                   className=" w-[90%] flex justify-between">
                     İL:  
                   <select name="" id=""
                   className="w-[77%] rounded-md text-gray-600"
                     value={province}
                     onChange={(event)=>{
                         setProvince(event.target.value);
                         setRelatedDistricts(CitiesJson[event.target.value]['districts'])}} >
                     <option value=''>İl</option>   
                       {cities.map(city => (
                       <option key={`city-${city}`} value={city}>
                           {city}
                     </option>))} 
                   </select>
                   </p>

                   {/* district/ district */}
                   <p
                   className=" w-[90%] flex justify-between">
                     İlçe:  
                     <select name="" id=""
                     className="w-[77%] rounded-md text-gray-600"
                     value={district}
                     onChange={(event)=>{setDistrict(event.target.value)}}
                     >
                   <option>İlçe</option>   
                   {relatedDistricts.map(district => (
                   <option key={`district-${district}`} value={district}>
                     {district}
                   </option>))}     
                     </select>
                   </p>
             </div>
            }
               {/* fatura address */}                    

             <div className="w-[90%] flex justify-between">
               <p>
                 Fatura Adresi
               </p>
               <textarea className="text-md rounded-md text-gray-600 w-[77%]"
               placeholder="Adres" required value={address} 
               onInput={(event)=>{setFaturaAddress(event.target.value)}}>

               </textarea>
             </div>

             <p
              className=" w-[90%] flex mb-0 mt-2 justify-between">
               Adres Başlığı:  
               <input disabled                     
               value={address_name}
               onChange={(event) => { setaddressName(event.target.value) }}
               className="h-[2.2rem]    rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             {/* <p>(province, ilçe, Mah., Sk., No.)</p> */}
           </div>//{/**end individual address */}
                //this column is compulsory for the iterany operator
               :


             // organizational adddress
           <div 
            className="bg-gray-600 h-full text-left pt-3 pl-1 text-sm">
             
             <p
              className=" w-[90%] flex justify-between">
               Firma Adı:  
               <input required                     
               value={organisation_name}
               onChange={(event) => { setfirmaAdi(event.target.value) }}
               className="h-[2.2rem]   rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             <p
              className=" w-[90%] flex justify-between ">
               Ülke:  
               <div className="w-[77%]">
                 <CountrySelector setCountryLabel={setCountryLabel}/>
               </div>
             </p>
               {/* province and district selection for turkey only shows if country is turkey */}
               {country==='Turkey'&&
               <div className="w-full">
                   <p
                   className=" w-[90%] flex justify-between">
                     İL:  
                   <select name="" id=""
                   className="w-[77%] rounded-md text-gray-600"
                     value={province}
                     onChange={(event)=>{
                         setProvince(event.target.value);
                         setRelatedDistricts(CitiesJson[event.target.value]['districts'])}} >
                     <option value=''>İl</option>   
                       {cities.map(city => (
                       <option key={`city-${city}`} value={city}>
                           {city}
                     </option>))} 
                   </select>
                   </p>

                   {/* district/ district */}
                   <p
                   className=" w-[90%] flex justify-between">
                     İlçe:  
                     <select name="" id=""
                     className="w-[77%] rounded-md text-gray-600"
                     value={district}
                     onChange={(event)=>{setDistrict(event.target.value)}}
                     >
                   <option>İlçe</option>   
                   {relatedDistricts.map(district => (
                   <option key={`district-${district}`} value={district}>
                     {district}
                   </option>))}     
                     </select>
                   </p>
               </div>
               }
               {/* fatura address */}
             <div className="w-[90%] flex justify-between">
               <p>
                 Fatura Adresi
               </p>
             <textarea className="text-md rounded-md text-gray-600 w-[77%]"
              placeholder="Adres" required value={address} 
              onInput={(event)=>{setFaturaAddress(event.target.value)}}>

              </textarea>
             </div>

              {/* vkn and vergi dairesi */}
             <div className="flex justify-end w-[90%] py-1">
               <div className="flex w-[77%] space-x-2">
                 <div className="text-left w-full">
                   <p className="mb-0">VKN</p>
                   <input required                   
                     value={vergi_kimlik_no}
                     onChange={(event) => { setvkn(event.target.value) }}
                     className="h-[2.2rem]  rounded-md text-gray-600 w-full"
                     type="text" />
                 </div> {/**end vkn */}

                 <div className="w-full">
                   <p className="mb-0">Vergi Dairesi</p>
                   <input required                     
                     value={vergi_dairesi}
                     onChange={(event) => { setvergiDairesi(event.target.value) }}
                     className="h-[2.2rem]   rounded-md text-gray-600 w-full"
                     type="text" />
                 </div> {/**end vergi dairesi */}
               </div>
             </div>

             <p
              className=" w-[90%] flex justify-between mt-2 mb-0">
               Adres Başlığı:  
               <input disabled                     
               value={address_name}
               onChange={(event) => { setaddressName(event.target.value) }}
               className="h-[2.2rem]   rounded-md text-gray-600 w-[77%]"
               type="text" />
             </p>

             {/* <p>(province, ilçe, Mah., Sk., No.)</p> */}
           </div>//{/**end organizational address */}
             }
         </div>
         
       </div>
       <div className="flex justify-around h-fit w-full">

               <p type=""
                   onClick={() => { props.props.readyForPayment() }}
                  className=" bg-red-400 px-2 h-[1.6rem] cursor-pointer rounded-md mt-1"
                  >
                    Vazgeç
                </p>

          <button type="submit" 
          className=" bg-green-400 px-2 rounded-md mt-1 h-[1.6rem]"
          >
            Kaydet
          </button>
       </div>

      </form>
    </div>
  )
}

export default EditBillnAddress