import { useEffect, useState } from "react";
import MyFooter from "../components/footer";
import { useLocation, useNavigate } from "react-router-dom";
import { CREATE_COMMENT, GET_EVENT_RELATED_COMMENTS, GET_SERVER_CURRENT_TIME, createBroadCastUrl, disapproveAttendee, generalGetMethos, generalPostMethod, getLeaderBoard, getSingleEvent, getUserPayments } from "../components/requests";
import EtkinlikBilgiTable from "../components/etkinlik-bilgi-table";
import { Button, Card } from "flowbite-react";
import KatilimciListItem from "../components/katilimci-list-item";
import { FloatingLabel, Form, FormGroup, FormLabel, InputGroup } from "react-bootstrap";
import { countdown, createDateObj, formatToTL, getSaleTimeStampFromEvent } from "../helpers";
import { format } from "date-fns";
import FenomenKatilimciListItem from "../components/fen-katilimci-list-item";



const FenEtkinlikGor = ()=>{
    const navigateThere = useNavigate();

    const location = useLocation();
    const eventInfo = location.state || {};

    // const [katilimciSayisi, setKatilimciSayisi] = useState(0);
    const [leaderBoard, setleaderBoard] = useState([]);
    const [errorMessage, seterrorMessage] = useState('');
    const [myTotalAmounPaid, setmyTotalAmounPaid] = useState(0);
    const [minPaymentRequired, setminPaymentRequired] = useState(0);
    const [minArtirmaTutari, setminArtirmaTutari] = useState(0);
    const [myPayments, setmyPayments] = useState([]);
    const [comments, setcomments] = useState([])
    const [noSaleMessage, setNoSaleMessage] = useState('Etkinlik Satışta değil!');
    const [eventOnSale, seteventOnSale] = useState(false);



  //Pul the following data from either local storage or navigate there 
  //mechanism thrown from the home page when satin al clicked
  const [etkinlikAdi, setEtkinlikAdi] = useState('');
  const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
  const [etkinlikTarihi, setEtkinlikTarihi] = useState('');
  const [etkinlikMekan, setEtkinlikMekan] = useState('');
  const [etkinlikInfo, setetkinlikInfo] = useState({});
  const [odemeTutari, setodemeTutari] = useState(); //amount to pay
  const [maxParticipants, setmaxParticipants] = useState(0);
  const [toggleLoadAttendees, settoggleLoadAttendees] = useState(true);
  const [countdowndata, setcountdowndata] = useState({})


  const [comment, setcomment] = useState('');

  const event_id = eventInfo.event_id ||0;  
  // console.log(finalList)  
  var user_id = '';
  if (JSON.parse(localStorage.getItem('user_id'))) {
      user_id = JSON.parse(localStorage.getItem('user_id'));
  }  
  const listHeader = <div className="row gap-0 text-white  w-100">
                          <div className="col-1 text-xs">Sira</div>
                          <div className="col-4 text-xs">İsim</div>
                          <div className="col-3 text-xs">Ödediği Tutari</div>
                          <div className="col-3 text-xs">Eylemler</div>

                          {/* <div className="col-1"></div> */}  
                       </div>

    //define get leader board handler
    function fetchAttendees(){
        const results = getLeaderBoard({"event_id":event_id}).then(
            (response)=>{
                if (!response.status) {                    
                    
                    seterrorMessage('Katılımcı listeyi güncelenemedi, tekrardan denenyor')
                }
                else if (response.data.status==='success')
                 {
                    if (response.data.leader_board.length>0) {
                        
                        seterrorMessage('')
                        // console.log(response.data);
                        setleaderBoard(response.data.leader_board);
                        // getPayments();
                    }else{//if leader board is empty everyone pays minartirmali tutari
                        // setminPaymentRequired(minArtirmaTutari)
                    }
                }
                else if (response.data.status==='failed') {
                    seterrorMessage('Katılımcı listeyi güncelenemedi, tekrardan denenyor')
                }
                else{                    
                    seterrorMessage('Katılımcı listeyi güncelenemedi, tekrardan denenyor')
                }
            }
        );
    }

    // get a single etkinlik info
    function getEtkinlikInfo (){
        const results = getSingleEvent({"event_id":event_id}).then(
            (response)=>{
                if (!response.status) {                    
                    
                    // seterrorMessage('sdfsjkdfksjf')
                }
                else if (response.data.status==='success' && response.data.data.length>0) {
                    const data = response.data.data;
                    setetkinlikInfo(data)
                    setEtkinlikAdi(data[0].etkinlik_adi)
                    setEtkinlikMekan(data[0].etkinlik_mekan)
                    setEtkinlikTarihi(data[0].etkinlik_tarihi)
                    setminArtirmaTutari(data[0].minimum_art_tutari)
                    setmaxParticipants(data[0].katilim_sayisi)
                    setminPaymentRequired(data[0].minimum_art_tutari)
                    // console.log(response.data.data);
                    // seterrorMessage('')
                    // console.log(response.data);                    
                }
                else if (response.data.status==='failed') {
                }
                else{                    
                    
                }
            }
        );
    }

    //for creating comment
    function handleCreateComment(event) 
        {
            event.preventDefault();
            generalPostMethod(CREATE_COMMENT,{'event_id':event_id, 'text':comment}).then(
                (response) => { 
                    if (response.status===200) {
                        setcomment("")
                        //Handle making an alert that comment sent with dialog popup
                    } else {
                       //Handle making an alert that comment sent with dialog popup

                    }
                 }
            )
        }

    //fetch event related comments
    function fetchComments() {
        generalGetMethos(GET_EVENT_RELATED_COMMENTS+'?event_id='+event_id).then(
            (response) => { 
                if (!response.status) {
                    //dialog to say comment not fetched
                } else if(response.status===200){
                    setcomments(response.data.comments)
                }
             }
        ).catch()
    }

    useEffect(() => {
        getEtkinlikInfo();
        //get payment leader board on inititial load of the page
        fetchAttendees();

        fetchComments();

        //establish connection to message broadcast
        var channelUri = createBroadCastUrl(event_id);
        // console.log(channelUri);

        var eventSource = new EventSource(channelUri);

        // console.log("about to retrieve message");
        eventSource.onmessage = function(event) {
        var message = JSON.parse(event.data);
        let parsed_data = JSON.parse(message.data)
        let max_allowed_participants = parsed_data['max_allowed_participants']       
        let leader_board = parsed_data['leader_board']
        if (leader_board.length>0) {
            
            setleaderBoard(leader_board);
        }else{
            // console.log(minArtirmaTutari);
            setminPaymentRequired(minArtirmaTutari)
        }
        };
            
    }, [myTotalAmounPaid,toggleLoadAttendees]);

     //useefect for the time chronometer/countdown
     useEffect(() => 
     {
        //get server timestamp and determine if event is on sale
        generalGetMethos(GET_SERVER_CURRENT_TIME).then((response)=>{
            if (response.status===200){
                let timestamp_ = response.data.current_server_timestamp;
                if (timestamp_ && etkinlikInfo.length>0) {
                    let curent_server_timestamp = new Date(timestamp_);
                    let event_sale_start_timestamp = getSaleTimeStampFromEvent(etkinlikInfo[0],"start");
                    let event_sale_end_timestamp = getSaleTimeStampFromEvent(etkinlikInfo[0],"end");
                    if (curent_server_timestamp>=event_sale_start_timestamp && curent_server_timestamp <event_sale_end_timestamp)
                        {
                           seteventOnSale(true);
                        }else if(curent_server_timestamp<event_sale_start_timestamp){//event sale yet to start
                           setNoSaleMessage('Etkinlik Satışta değil!')
                           seteventOnSale(false);
                        }else if(curent_server_timestamp >event_sale_end_timestamp){//sale ended
                           setNoSaleMessage('Satış Sona Erdi!')
                           seteventOnSale(false);
                        }else{
                            seteventOnSale(false);
                        }
                }

            }
        })

        if (etkinlikInfo.length>0) {
            
            const target_timestamp = createDateObj(etkinlikInfo[0].satis_bitis_tarihi,
                etkinlikInfo[0].s_bisaat)
    
            const interval = setInterval(() => {
            const cntDnData = countdown(target_timestamp) //starter_milSeconds)
    
            setcountdowndata(cntDnData)
            }, 600); // 60000 milliseconds = 1 minute
        
            // Clear the interval when the component unmounts
            return () => clearInterval(interval);
        }
     }, [etkinlikInfo]);

    //it is necessary to have a separate useeffect for calculating user payments
    //since the above useeffect has myTotalamountPaid in it's dependency list
    //putting getPayments() function in the above useeffect will cause infinite loop
    // useEffect(() => {
    //     getPayments()    
    
    // }, []);

    return(

    <div className="mt-14 justify-content-center px-3">
        <div className=" col-sm-6 mx-auto justify-content-center">
        <div className="row  flex-row justify-content-center">
            <div className="flex">
                <label className="text-white text-xs w-[5rem] pr-2 flex items-center"
                 htmlFor="">Etkinlik Adı:</label>
                <h3 id="etknlk-adi" className="text-white w-full">{etkinlikAdi}</h3>
            </div>
        <div className="row g-1 mb-4 justify-content-center">
            <div className="col-8">
                <EtkinlikBilgiTable event_id ={event_id}/>
            </div>
            <div className="col-4">
                <div className="h-[5.7rem] rounded-md bg-white p-1">
                    <h5 className="text-sm m-0 mb-2" >Kalan Süre</h5>
                    {countdowndata.seconds>=0 && eventOnSale ?
                    <p className='text-[0.6rem] my-0 bg-red-200 rounded-md'>
                        <span className="ml-1">{countdowndata.days} Gün</span>
                        <span className="ml-1">{countdowndata.hours} Saat</span>
                        <span className="ml-1">{countdowndata.minutes} Dakıka</span>
                        <span className="ml-1">{countdowndata.seconds} Saniye</span>
                    </p>
                :
            <p className="bg-red-200 mx-auto w-fit px-2 rounded-md text-sm" >{noSaleMessage}</p>

                }
                </div>        
            </div>
        {/* <div className="row py-3 bg-danger-subtle  "> */}
            
        
        {/* </div>     */ }

        </div>
        <h3 className="text-white text-start">Ödeme Yapanlar</h3>
        {listHeader}
        <div className="row bg-white overflow-y-auto
          flex h-25 justify-content-center border
           rounded-3 w-100 mb-3" style={{maxHeight:'15rem'}}>
            {/* {leaderBoard.map((record, index) => ( */}
                <FenomenKatilimciListItem  
                 event_id ={event_id}
                //  red={index+1 > maxParticipants} index={index}
                //   item={record}
                  leaderBoard={leaderBoard}
                  />
            {/* ))} */}
            {/* {finalList} */}
        </div>
        <div className="row w-100 px-0">
        </div>


            {/* user comments */}

            <div className="overflow-y-auto h-[25rem]">
            {comments&&
                comments.map((comment) => (
                    <div key={comment.id}>
                        <div className="w-full flex px-1  mb-1">
                        {/* nick */}
                            <p className="w-fit mb-0  text-xs text-white text-start">
                                {comment.nick}
                            </p>

                            <p className="w-fit mb-0 text-xs text-white ml-auto">
                                {format(Date(comment.created_at),"dd-MM-yyyy HH:mm")}
                            </p>
                        </div>
                        <p className='feno-yorumlarim text-sm' >
                            {comment.text}
                        </p>
                    </div>

            ))}
            </div>
            </div>
            </div>
    </div>
    );
}

export default FenEtkinlikGor;