import React, { useEffect, useState } from 'react';
import 'react-credit-cards-2/dist/es/styles-compiled.css'; // Import the styles for react-credit-cards
import Cards from 'react-credit-cards-2';
import { GET_USER_CARDS_URL, PAYMENT_3dLINK_GENERATOR_URL, generalGetMethos, generalPostMethod } from '../components/requests';

const CreditCardForm = (props) => {

  const [formData, setFormData] = useState({
    number: '',
    name: '',
    expiry: '',
    cvc: '',
    focused: '',
    save:false,
    cardAlias:'',
    randomID:'', 
    order_id:props.order_info.order_id || -1
  });

  const [displayCardSelector, setdisplayCardSelector] = useState(false);
  const [cardSelected, setcardSelected] = useState(false);
  const [allCards, setallCards] = useState([]);
  const [selectedCardAlias, setselectedCardAlias] = useState(null);


  const checkHandler = () => {
    // Update state with formatted value
    setFormData({ ...formData, ['save']: !formData.save,
                ['cardAlias']:'' });
  }

  const handleSubmit = (event) => { 
    event.preventDefault();

    generalPostMethod(PAYMENT_3dLINK_GENERATOR_URL,formData).then(
      (response) => { 
      if (response.status===200) {
        // console.log('response',response);
        props.setshow3dIframe(true);
        props.setIframeSrc(response.data.iframe_url);
        // console.log("Success", response.data.iframe_url);
      } else {
        // console.log("Failure", response);
        //handle errors display later
        
      }
     })
   }

  const handleCardAliasSelected = (e) => {
    let selected_option = e.target.options[e.target.selectedIndex];
    setFormData({ ...formData, ['cardAlias']: selected_option.value,
                ['randomID']: selected_option.dataset.id });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;    
    let formattedValue = value;

    // Format credit card number
    if (name === 'number') {
      // Remove non-numeric characters
      formattedValue = value.replace(/\D/g, '');

      // Add space after every 4 characters
      formattedValue = formattedValue.replace(/(.{4})/g, '$1 ').trim();

      // Limit to 19 characters (16 digits + 3 spaces)
      formattedValue = formattedValue.substr(0, 19);
    }

    // Format expiry (MM/YY)
    if (name === 'expiry') {
      // Remove non-numeric characters
      formattedValue = value.replace(/\D/g, '');

      // Add slash after 2 characters
      if (formattedValue.length > 2) {
        formattedValue = formattedValue.substr(0, 2) + '/' + formattedValue.substr(2);
      }
    }

    // Update state with formatted value
    setFormData({ ...formData, [name]: formattedValue });
  };

  // ..useefect for fetching Cards
  useEffect(() => {
    generalGetMethos(GET_USER_CARDS_URL).then(
      (response) => {
        if (response.status === 200 && response.data.cs.length > 0) {
          setallCards(response.data.cs);
          setdisplayCardSelector(true);
        }
      }
    )
  }, [])
  

  return (
    <div className='mt-[4rem] h-full w-full flex justify-center'>
      {displayCardSelector? //if saved cards exists
      <div className='bg-gray-400 md:w-[40%] h-[25rem] p-4 rounded-md'>
        <div className='w-[100%]  flex justify-end text-[0.8rem]'>
          <span className='cursor-pointer bg-blue-300 rounded-md px-2'
          onClick={() => setdisplayCardSelector(false)}
          >
            Yeni kartla öde(ekle)
          </span>
          </div>
        <h1 className='text-white text-sm '>ÖDEME KART SEÇME</h1>
        <span></span>
          <form onSubmit={handleSubmit} action=""
          className='mt-[3rem] '>
            <div className='flex gap-x-1 justify-center'>
              <h6>Toplam:</h6>
              {`${props.order_info.payment_amount} TL`}
            </div>

          <div className=' h-[6rem] '>
            <select name="cardAlias" id=""
            required
            onChange={handleCardAliasSelected}
            className='w-[18rem]  space-y-2 p-2 rounded-md '

            value={formData.cardAlias}
            >
              <option value="">Kart Seçin</option>
              {allCards.map((card)=>(
                <option value={card.card_alias} data-id={card.randomID}>{card.card_alias}</option>
              ))}  
            </select>
          </div>

            <button
              type="submit"
              className='text-white bg-blue-400 h-[2rem] text-sm w-[7rem] px-2 rounded-md'>
              Ödememe Yap
            </button>
          
          </form>
      </div>
      :
      //if no saved cards exists
      <div className='bg-gray-400 md:w-[40%] rounded-md'>
        <h1 className='text-white text-sm'>KART BİLGİLERİ</h1>
      <Cards
        number={formData.number}
        name={formData.name}
        expiry={formData.expiry}
        cvc={formData.cvc}
        focused={formData.focused}
        placeholders={{'name':"KART ÜZERİNDEKİ İSİM"}}
        locale={{'valid':'Geçerlilik'}}
      />
      <div className='flex gap-x-1 justify-center mb-0'>
              <h6 className='mb-0'>Toplam:</h6>
              {`${props.order_info.payment_amount} TL`}
            </div>
      <form onSubmit={handleSubmit} action=''
       className='mt-[1rem] flex justify-center space-y-2 '>
        <div className='w-[18rem] bg-gray-400 space-y-2 p-2 rounded-md h-[12.8rem]'>
            <input
            required
            className='w-full rounded-md'
            type="text"
            name="number"
            placeholder="Kart Numarası"
            value={formData.number}
            onChange={handleInputChange}
            />
            <input
            required
            className='w-full rounded-md'
            type="text"
            name="name"
            placeholder="Kart Üzerindeki İsim"
            value={formData.name}
            onChange={handleInputChange}
            />
            
            <div className=' flex space-x-8'>
            <input
            className='w-[10rem] rounded-md'
            type="text"
            name="expiry"
            placeholder="MM/YY Geçerlilik"
            value={formData.expiry}
            onChange={handleInputChange}
            maxLength="5" // Limit to MM/YY format
            />
            <input
            required
            className='w-[5rem] rounded-md'
            type="text"
            name="cvc"
            placeholder="CVC"
            value={formData.cvc}
            onChange={handleInputChange}
            maxLength="3"
            />

            </div>
            
            <div className='w-full flex items-center'>
                <label htmlFor="">Karti Kaydet</label>
                <input
                className=' rounded-md ml-1'
                type="checkbox"
                checked={formData.save}
                name="cardAlias"
                onChange={checkHandler}
                />
            {formData.save&&
                <input
                required
                className='w-[9rem] ml-auto rounded-md flex'
                type="text"
                name="cardAlias"
                placeholder="Kart İsim"
                value={formData.cardAlias}
                onChange={handleInputChange}
                />
             }
            </div>
            
        <button
        type="submit"
        className='text-white bg-blue-400 h-[2rem] w-fit px-2 rounded-md'>
        Ödeme Yap
        </button>
        </div>

      </form>
      </div>
      }
    </div>
  );
};

export default CreditCardForm;
