// import logo from '../../logo.svg';
import '../../App.css';

import '@coreui/coreui/dist/css/coreui.min.css';
import { CContainer,CRow,CCol  } from '@coreui/react';

import { Modal} from "flowbite-react";
import React, { useEffect, useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';

import Etkinlik from '../etkinlik-card';
import Etkinlik2 from '../etkinlik-card2';
import fatih2 from '../../fatih2.jpeg';
import olcay from '../../olcay.jpeg';
import Footer from '../footer';
import { getAllEvents } from '../requests';
import EtkinlikGeneral from '../etkinlikGeneral-card';


const Events = ()=>{
    const [eventsInfo, setEventsInfo] = useState([]);
    const [server_timestamp, setserver_timestamp] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [showMaxParticipantPopup, setshowMaxParticipantPopup] = useState(false);
    const [evenRules, setevenRules] = useState("");
    useEffect(()=>{
        // async function getEvents_() {
            const results = getAllEvents().then(
            (response)=>{
                if (!response.status) {
                    
                }
                else if (response.status===200) {
                        if (response.data.events.length>0) {
                            setEventsInfo(response.data.events)
                            setserver_timestamp(response.data.timestamp)
                            //save participation info of sabit events to local storage
                            localStorage.setItem('participation', JSON.stringify(response.data.participation));
                        }
                    }
                    
                }
        )
      
},[]);

    return(

        <div className='homebody'>
            {/* Modal for displaying event rules */}

            <Modal className='mt-14'
            show={openModal}  onClose={() => setOpenModal(false)}>
                <Modal.Header>Etkinliğin Kuralları</Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6 p-6 overflow-y-auto h-[15rem]">
                            <p
                             className="text-base leading-relaxed text-gray-500
                              dark:text-gray-400">
                                {evenRules&&evenRules}
                            </p>
                        </div>
                    </Modal.Body>
            </Modal>

            {/* Modal for displaying Max participants reached popup */}

            <Modal className='mt-14'
             show={showMaxParticipantPopup}  onClose={() => setshowMaxParticipantPopup(false)}>
                <Modal.Header className=''>Etkinliğe Katılım Sayısına Ulaşıldı.</Modal.Header>
                    <Modal.Body>
                        <div className="space-y-6 p-6 overflow-y-auto h-[4rem]">
                            <p
                             className="text-base leading-relaxed text-gray-500
                              dark:text-gray-400">
                                {/* {evenRules&&evenRules} */}
                            </p>
                        </div>
                    </Modal.Body>
            </Modal>

            <CRow>
            <h3 style={{color:'white'}}>Aktif Etkinlikler</h3>
            </CRow>
            <div className='row d-flex'>
                

                {/* <div className='row justify-content-around  '> */}
                <div className='col justify-content-center d-flex'><Etkinlik reactImage={olcay} /></div>
                <div className='col justify-content-center d-flex' ><Etkinlik2 reactImage={fatih2}/></div>
                
                {eventsInfo&&eventsInfo.map((componentInfo)=>(
                    <div className='col justify-content-center  d-flex'>
                        <EtkinlikGeneral
                         props={componentInfo} server_timestamp={server_timestamp}
                                setOpenModal={setOpenModal} setevenRules={setevenRules}
                                setshowMaxParticipantPopup={setshowMaxParticipantPopup}
                        />
                        </div>))
                }                              

            </div>    
        
        </div>
    );

    
}

export default Events;