import React, { useState,useRef, useContext  } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { withRouter, useNavigate } from "react-router-dom";
import '@coreui/coreui/dist/css/coreui.min.css';
import { FiEye, FiEyeOff } from "react-icons/fi";

import "../App.css";
import MyFooter from '../components/footer';
// import { CContainer } from '@coreui/react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { AUTH_COOKIE_LINK, generalGetMethos, generalPostMethod, LOGIN_URL_PROD } from '../components/requests';
import { error_codes, ur } from '../config';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;



const LoginForm = (props) => { 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setshowPassword] = useState(false);

  const navigateThere = useNavigate();

  // const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    // const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    
    setErrorMessage("");
    //get csrf token. note: this doesnt requre use credentials or cors restrictions
    //this is used to ensure cors restrictions on subsequent requests
    generalGetMethos(AUTH_COOKIE_LINK).then((response) => {
      if (response.status === 204) {//if it succeeds then proceed to login
        
        generalPostMethod(LOGIN_URL_PROD, JSON.stringify({ email,password })).then((response) => {
          if (!response.status) {
            setErrorMessage("Beklenmeyen bir hata oluşmuş");
            setSuccess(false);
          } else if (response.status === 200) {
            setErrorMessage("");
            localStorage.setItem('isLoggedIn', JSON.stringify('true'));
    
            // console.log(response?.data.user)
            var parsedUser = JSON.parse(response.data.user)
    
            //This will be needed for the satin-al page
            localStorage.setItem('first_name', JSON.stringify(parsedUser.first_name));
            localStorage.setItem('last_name', JSON.stringify(parsedUser.last_name));
            localStorage.setItem('phone', JSON.stringify(parsedUser.phone));
            localStorage.setItem('user_type', JSON.stringify(parsedUser.user_type));
            localStorage.setItem('user_id', JSON.stringify(parsedUser.id));
            localStorage.setItem('email', JSON.stringify(parsedUser.email));
            localStorage.setItem('verified', JSON.stringify(parsedUser.verified));
            props.setIsLoggedin('true');
            parsedUser.user_type==ur.e_owner?navigateThere('/fenomen-admin'):navigateThere('/');
            
          }
          else if (response.status === error_codes.invalid_credentials) {
            setErrorMessage("Kullanıcı Adı veya Şifre Hatalı.");
            setSuccess(false);
          }
          else{
            setErrorMessage("Beklenmeyen bir hata oluşmuş");
            setSuccess(false);}
        })//end of login post method


        //else for csrf error
      }else{
        setErrorMessage("Beklenmeyen bir hata oluşmuş");
        setSuccess(false);
      }


      });//end of get csrf


    }//end of handle login
  
  return (
    
    <div  className="login-page">
      {/* <CContainer> */}
        <h2 style={{color:'white'}}>Üye Giriş</h2> <br/>
      
      {errorMessage && <p className='text-red-500 bg-red-200'>{errorMessage}</p>}

    <Form onSubmit={handleLogin}>
      <Form.Group className="mb-3" >
        {/* <Form.Label>Kullanici Adı veya mail</Form.Label> */}
        <Form.Control type="email" id="username" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Mail adresi (örnek@gmail.com)" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>

      <Form.Group className="relative" >
        {/* <Form.Label>Şifre</Form.Label> */}
        <Form.Control type={showPassword ? "text" : "password"}
         id="password"
         value={password} onChange={(e) => setPassword(e.target.value)}
         placeholder="Şifre" />
         <span className="absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer">
         {showPassword ? <FiEyeOff onClick={() => setshowPassword(!showPassword)}/> :
         <FiEye onClick={() => setshowPassword(!showPassword)}/>
          }

         </span>
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}
      < p className='text-xs w-full text-blue-500 text-start cursor-pointer '
        onClick={() => { navigateThere('/password-reset-email') }}
        >
          Şifremi unuttum</p>
      <Button className=''  type="submit">
        Giriş Yap
      </Button>
    </Form>
    
    {/* </CContainer> */}
   
    </div>
    
    
    
   
  );
};

export default LoginForm;
