
import { useEffect, useState } from "react";
import "../App.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYMENT_LINK_GENERATOR_URL } from "../components/requests";
import CreditCardForm from "./payment-card-details";

// const PAYMENT_LINK_GENERATOR_URL = 'http://127.0.0.1:8000/api/get-iframe-url'; //dev


const MakeBiddedPayment = ()=>{
    const location = useLocation();
    const orderInfo = location.state || {};

    const [iframeSrc, setIframeSrc] = useState('');
    // const [etkinlikUcreti, setEtkinlikUcreti] = useState('');
    const [show3dIframe, setshow3dIframe] = useState(false)
    const [showCardForm, setshowCardForm] = useState(true)

    const navigateThere = useNavigate();



    const getIframeLink = async ()=>{

            const order_id = JSON.parse(localStorage.getItem('order_id'));
            // console.log("order id is: ",order_id)
            const results = axios.post(PAYMENT_LINK_GENERATOR_URL,
            JSON.stringify({ 'etkinlikUcreti':orderInfo.payment_amount,'order_id':orderInfo.order_id}),
            {
                headers: { 'Content-Type': 'application/json',
                'Accept':'application/json' },
                withCredentials: true
            } ).then(
                (response) => {
                    if (!response.status) {
                         //handle error
                    }
                    else if (response.status===200) {                        
                        setIframeSrc(response.data.iframe_url);
                        setshow3dIframe(true);
                    }
                }
            ).catch(
                (response) => { 
                    //handle error
                 });

    }

    return(
        <div className="mt-5 h-screen"> 
        {show3dIframe?
        <div >
            <div id="payment_window" className="payment_modal">';
            {/* <div><button class="close" id="modalClose" onclick="closeModal()" class="close" style="float: right;">&times; </button></div>'; */}
            <iframe  id="payment-iframe" title="payment iframe" width="100%" height="100%" srcdoc={iframeSrc} sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts" frameborder="2"></iframe> </div>';
            {/* use the following (with src instead of srcdoc) if your iframe souce is an html code instead of a link*/}
            {/* <iframe  id="payment-iframe" title="payment iframe" width="100%" height="100%" srcdoc={iframeSrc} sandbox="allow-same-origin allow-top-navigation allow-forms allow-scripts" frameborder="2"></iframe> </div>'; */}
 
        </div>
        
        :
        
        <CreditCardForm
                 setIframeSrc={setIframeSrc} 
                 setshow3dIframe={setshow3dIframe}
                 order_info={orderInfo}
                 />
        }
        </div>
    );
}

export default MakeBiddedPayment;